import type { MessagePostPages, MessagePostPagesAndParams } from '../routes/endUserRoutes';
import useEndUserRoutes from '../routes/useEndUserRoutes';
import useSeoProperties from './seo/useSeoProperties';

interface PostPageRoute {
  /**
   * @param boardId Current board id
   * @param categoryId Current category id
   * @param messagePostPage Post page name
   * @returns Path to the post page
   */
  getPostPageRoute: (
    boardId: string,
    categoryId: string,
    messagePostPage: MessagePostPages
  ) => string;
}

/**
 * Hook for getting a post page route
 *
 * @author Juan Manuel Suarez
 */
export default function usePostPageRoute(): PostPageRoute {
  const { router } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();

  /**
   * @param boardId Current board id
   * @param categoryId Current category id
   * @param messagePostPage Post page name
   * @returns Path to the post page
   */
  function getPostPageRoute(
    boardId: string,
    categoryId: string,
    messagePostPage: MessagePostPages
  ): string {
    return router.getRelativeUrlForRoute<MessagePostPagesAndParams>(messagePostPage, {
      boardId: getCaseSensitivePath(boardId),
      categoryId: getCaseSensitivePath(categoryId)
    });
  }

  return {
    getPostPageRoute
  };
}
