import { useApolloClient } from '@apollo/client';
import HeroButton from '@aurora/shared-client/components/common/HeroButton/HeroButton';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import AuthFlowContext from '@aurora/shared-client/components/context/AuthFlowContext/AuthFlowContext';
import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import usePostPageRoute from '@aurora/shared-client/components/usePostPageRoute';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { isNodeBoard, isNodeGroupHub } from '@aurora/shared-client/helpers/nodes/NodeUtils';
import type {
  GroupHubMessagePostPagesAndParams,
  MessagePostPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import { MembershipType } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  EndUserComponent,
  EndUserPages,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { GraphQLFormattedError } from 'graphql';
import type { MutableRefObject } from 'react';
import React, { useCallback, useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { MembershipAction } from '../../../types/enums';
import type {
  GroupHubDetailsQueryVariables,
  JoinNodeMutation,
  JoinNodeMutationVariables,
  NodeViewFragment
} from '../../../types/graphql-types';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';
import useGroupHubDetails from '../../grouphubs/useGroupHubDetails';
import joinNodeMutation from '../../memberships/JoinMembershipNode.mutation.graphql';
import { updateNode } from '../../memberships/MembershipActionHelper';
import useTranslation from '../../useTranslation';
import localStyles from '../NodeHeader/NodeHeader.module.pcss';

const log = getLog(module);
interface Props {
  /**
   * Text alignment
   */
  alignment?: TextAlignment;
  /**
   * Action button title
   */
  actionButtonTitle?: string;
  /**
   * Node
   */
  node: NodeViewFragment;
  /**
   * Group hub id
   */
  groupHubId?: string;
  /**
   * Board id
   */
  boardId?: MutableRefObject<string>;
  /**
   * Category id
   */
  categoryId?: MutableRefObject<string>;
  /**
   * Pass a default title to the post creation page
   */
  createPostDefaultSubject?: string;
}
/**
 * Node Header action button
 *
 * @author Nicolas Pascual
 */
const NodeHeaderActionButton: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  alignment,
  actionButtonTitle,
  groupHubId,
  categoryId,
  boardId,
  createPostDefaultSubject
}) => {
  const cx = useClassNameMapper(localStyles);
  const { contextNode } = useContext(AppContext);
  const tenant = useContext(TenantContext);
  const { addToast } = useToasts();
  const { isAnonymous, isPartiallyRegistered, isFullyRegistered, confirmEmailStatus } =
    useRegistrationStatus();
  const { showAuthenticationModal } = useContext(AuthFlowContext);
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const { data: ssoPropsData, loading: ssoPropsLoading } = useCommunitySsoProperties(module);
  const client = useApolloClient();
  const uidSeed = useUIDSeed();
  const { router } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { getPostPageRoute } = usePostPageRoute();
  const ssoEnabled = checkPolicy(ssoPropsData?.community?.ssoProperties?.ssoEnabled);
  const { triggerAuthFlow } = useAuthFlow();
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.NODE_HEADER_ACTION_BUTTON
  );
  const [joinNode] = useMutationWithTracing<JoinNodeMutation>(module, joinNodeMutation);
  const { showSsoRegistrationModal } = useContext(SsoRegistrationContext);
  const variables: GroupHubDetailsQueryVariables = {
    id: contextNode.id,
    useGroupHubDescendants: true,
    useMembershipInformation: true,
    useGroupHubPolicies: true
  };
  const {
    queryResult: { data },
    loading: groupHubInfoLoading
  } = useGroupHubDetails(variables, contextNode.nodeType !== NodeType.GROUPHUB);

  const redirectToCreatePost = useCallback(async () => {
    const currentBoardId = boardId?.current;
    const currentCategoryId = categoryId?.current;
    const { messagePostPage } = ConversationStyleBehaviorHelper.getInstance(node as Board);
    await router.pushRoute<MessagePostPagesAndParams>(
      messagePostPage,
      {
        boardId: getCaseSensitivePath(currentBoardId),
        categoryId: getCaseSensitivePath(currentCategoryId)
      },
      {
        [EndUserQueryParams.MESSAGE_SUBJECT]: createPostDefaultSubject
      }
    );
  }, [boardId, categoryId, router, getCaseSensitivePath, createPostDefaultSubject, node]);

  if (groupHubInfoLoading || ssoPropsLoading || textLoading) {
    return null;
  }
  const groupHub = data?.groupHub;

  /**
   * Renders error feedback in a banner toast when an error occurs while taking an action
   */
  function renderError(): void {
    const uid = uidSeed('error-join-grouphub');
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: formatMessage('errorHeader'),
      autohide: true,
      message: formatMessage('errorMessage'),
      delay: 4000,
      id: uid
    };
    addToast(toastProps);
  }

  /**
   * Function to handle errors resulting from a graphQL mutation call
   *
   * @param rollback rollback function passed from the GraphQL mutate's update method
   * @param errors errors (if any) from GraphQL call
   */
  function handleError(rollback: () => void, errors: readonly GraphQLFormattedError[]): void {
    log.error('Unable to join Group Hub: %O', errors);
    if (rollback) {
      rollback();
    }
    renderError();
  }

  /**
   * Renders success feedback in a flyout toast when action is successful
   */
  function renderSuccessFeedback(): void {
    const uid = uidSeed('success-join-grouphub');
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.SUCCESS,
      title: formatMessage('join.successHeader'),
      autohide: true,
      message: formatMessage('join.successMessage'),
      delay: 4000,
      id: uid
    };
    addToast(toastProps);
  }
  /**
   * Event handler for Join Group Hub action
   */
  async function onJoinMembershipNodeHandler(): Promise<void> {
    const mutationPayload: JoinNodeMutationVariables = {
      nodeId: contextNode.id
    };
    const { errors, rollback } = await updateNode(tenant, {
      nodeId: contextNode.id,
      mutate: joinNode,
      membershipInformation: groupHub,
      action: MembershipAction.JOIN_MEMBERSHIP_NODE,
      mutationPayload,
      client
    });
    if (errors?.length > 0) {
      handleError(rollback, errors);
      return;
    }
    renderSuccessFeedback();
  }

  async function handleUserFlow(): Promise<void> {
    if (!confirmEmailStatus) {
      addUpdateEmailToast();
    } else {
      await redirectToCreatePost();
    }
  }

  async function handleSsoFlow(): Promise<void> {
    if (isAnonymous) {
      const { messagePostPage } = ConversationStyleBehaviorHelper.getInstance(node as Board);
      await triggerAuthFlow(
        () => {},
        multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN,
        router.getCurrentRouteAndParams(),
        getPostPageRoute(boardId?.current, categoryId?.current, messagePostPage)
      );
    }
    if (isPartiallyRegistered) {
      showSsoRegistrationModal(true);
    }

    if (isFullyRegistered) {
      redirectToCreatePost();
    }
  }

  async function onCreatePostActionHandler(): Promise<void> {
    if (boardId.current) {
      if (isNodeBoard(node) && categoryId.current) {
        if (ssoEnabled) {
          handleSsoFlow();
          return;
        }
        if (isAnonymous) {
          showAuthenticationModal(multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN, {
            route: EndUserPages.PostPage,
            params: {
              categoryId: categoryId.current,
              boardId: boardId.current
            },
            query: {
              [EndUserQueryParams.MESSAGE_SUBJECT]: createPostDefaultSubject
            }
          });
        } else {
          await handleUserFlow();
        }
      }
      if (isNodeGroupHub(contextNode) && groupHubId) {
        await router.pushRoute<GroupHubMessagePostPagesAndParams>(EndUserPages.GroupHubPostPage, {
          groupHubId: getCaseSensitivePath(groupHubId),
          boardId: getCaseSensitivePath(boardId.current)
        });
      }
    } else {
      log.error(
        'Error in trying to create a post: %s %s',
        !categoryId.current ? 'undefined categoryId' : '',
        !boardId.current ? 'undefined boardId' : ''
      );
    }
  }

  /**
   * Function to determine required action when action button is clicked
   */
  function handleClick(): void {
    if (
      isNodeGroupHub(contextNode) &&
      groupHub.membershipType === MembershipType.Open &&
      checkPolicy(groupHub.membershipPolicies.canJoin)
    ) {
      onJoinMembershipNodeHandler();
    } else {
      onCreatePostActionHandler();
    }
  }

  return (
    <>
      <HeroButton
        className={cx('lia-btn', { 'order-lg-1': alignment === TextAlignment.RIGHT })}
        onClick={(): void => handleClick()}
        data-testid="NodeHeader.ActionButton"
      >
        {actionButtonTitle}
      </HeroButton>
    </>
  );
};

export default NodeHeaderActionButton;
